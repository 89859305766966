<template>
  <div class="ad">
    <div class="content">
      <div
        class="card"
        @click="jump(dataSource)"
        :style="[
          (dataSource.jumpType == 1 && dataSource.jumpUrl == '') ||
          (dataSource.jumpType == 2 && dataSource.resourceId == 0)
            ? ''
            : 'cursor: pointer;',
        ]"
      >
        <img :src="dataSource.coverPath" alt="ad" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ad",
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dataSource: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(_, { emit }) {
    const jump = (data) => {
      emit("jump", data);
    };
    return {
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.ad {
  .content {
    .mixinWrap();
    .card {
      .mixinImgWrap(1200px; 180px);
      border-radius: 8px;
    }
  }
}
</style>
